#root {
  height: 100%;
  width: 100vw;
  overflow-x: hidden
}

#rendering-canvas {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1
}

#loading-screen {
  opacity: 1;
  transition: opacity 1s ease, visibility 1s ease;
  visibility: visible
}

#loading-screen.hidden {
  opacity: 0;
  visibility: hidden
}

#section-container {
  height: 100%;
  overflow: auto;
  position: relative;
  width: 100vw;
}

#scroll-animation-container {
  height: 30000px;
  position: relative;
  width: 100%
}

#scroll-animation-container section {
  height: 100vh;
  max-height: 100%;
  position: absolute;
  width: 100%
}

#scroll-animation-container section p {
  font-size: 1.18rem;
}

#scroll-animation-container section .content {
  color: #fff;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1.875rem;
  left: 50%;
  line-height: 1.5;
  opacity: 0;
  padding: .3125rem 0.625rem;
  position: absolute;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, .6);
  top: calc(50% + 1rem);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: opacity .2s ease-in, visibility .2s ease-in, -webkit-transform .2s ease;
  transition: opacity .2s ease-in, visibility .2s ease-in, transform .2s ease;
  transition: opacity .2s ease-in, visibility .2s ease-in, transform .2s ease, -webkit-transform .2s ease;
  visibility: hidden;
  width: 45%
}

#scroll-animation-container section .next {
  cursor: pointer;
  margin-top: 1.25rem;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease
}

#scroll-animation-container section .next:hover {
  -webkit-transform: translateY(20%);
  transform: translateY(20%)
}

#scroll-animation-container section .next object {
  height: 1.875rem;
  pointer-events: none;
  width: 1.875rem
}

#scroll-animation-container section h2 {
  color: #f4d283;
  font-family: SairaRegular;
  font-size: 3.125rem;
  margin: 0;
  text-transform: uppercase
}

#scroll-animation-container section.active .content {
  opacity: 1;
  visibility: visible
}

#scroll-animation-container section.active#section-1 .content, #scroll-animation-container section.active#section-2 .content, #scroll-animation-container section.active#section-3 .content, #scroll-animation-container section.active#section-4 .content, #scroll-animation-container section.active#section-5 .content {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

#scroll-animation-container section.center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center
}

#scroll-animation-container section#section-1 {
  top: 4385px
}

#scroll-animation-container section#section-1 .content {
  left: unset;
  top: 3.5rem;
  right: 7rem;
  transform: translate(0, 0) !important;
}

#scroll-animation-container section#section-2 {
  top: 8831px
}

#scroll-animation-container section#section-2 .content {
  transform: translate(0, 0) !important;
  left: 2rem;
  top: 9rem;
}

#scroll-animation-container section#section-3 {
  top: 14375px
}

#scroll-animation-container section#section-3 .content {
  left: 30%;
  top: 50%
}

#scroll-animation-container section#section-4 {
  top: 23000px
}

#scroll-animation-container section#section-4 .content {
  left: 30%;
  top: 50%
}

#scroll-animation-container section#section-5 {
  top: 27000px
}

#scroll-animation-container section#section-5 .content {
  left: unset;
  top: 7rem;
  right: 7rem;
  transform: translate(0, 0) !important;
}

#section-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: 0rem 1.5625rem;
  position: fixed;
  right: 0;
  row-gap: 1.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2
}

#section-navigation .arrow {
  cursor: pointer;
  opacity: .3;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: opacity visibility .5s ease ease .5s, -webkit-transform .5s ease;
  transition: transform .5s ease, opacity visibility .5s ease ease .5s;
  transition: transform .5s ease, opacity visibility .5s ease ease .5s, -webkit-transform .5s ease;
  visibility: visible
}

#section-navigation .arrow.disabled {
  opacity: 0;
  visibility: hidden
}

#section-navigation .arrow object {
  height: 1.875rem;
  pointer-events: none;
  width: 1.25rem
}

#section-navigation .arrow:hover#previous {
  -webkit-transform: translateY(-25%);
  transform: translateY(-25%)
}

#section-navigation .arrow:hover#next {
  -webkit-transform: translateY(25%);
  transform: translateY(25%)
}

#section-navigation #sections {
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem
}

#section-navigation #sections .pin-container {
  align-items: center;
  -webkit-column-gap: 1rem;
  column-gap: 0.625rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: SairaRegular;
  font-size: 1.09375rem;
  justify-content: flex-end;
  text-transform: uppercase
}

#section-navigation #sections .pin-container .pin {
  background: hsla(0, 0%, 100%, .1);
  border-radius: 50%;
  height: 0.75rem;
  margin-right: .25rem;
  transition: background .5s ease;
  width: 0.75rem
}

#section-navigation #sections .pin-container .description {
  color: hsla(0, 0%, 100%, .5);
  font-size: 0.7rem;
  opacity: 0;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: color .5s ease, opacity .5s ease, -webkit-transform .5s ease;
  transition: color .5s ease, transform .5s ease, opacity .5s ease;
  transition: color .5s ease, transform .5s ease, opacity .5s ease, -webkit-transform .5s ease
}

#section-navigation #sections .pin-container:hover .description {
  color: #fff;
  -webkit-transform: translate(-.3125rem);
  transform: translate(-.3125rem)
}

#section-navigation #sections .pin-container:hover .pin {
  background: #fff
}

#section-navigation #sections .pin-container.active .pin {
  background: #fff;
  -webkit-transform: scale(1.5);
  transform: scale(1.5)
}

#section-navigation #sections .pin-container.active .description {
  color: #fff
}

#section-navigation #sections:hover .pin-container .description {
  opacity: 1;
}

@media (max-width:600px) {
  #scroll-animation-container section {
    width: 100%
  }

  #scroll-animation-container section .content {
    width: 80%
  }

  #scroll-animation-container section h2 {
    font-size: 1.875rem
  }

  #scroll-animation-container section p {
    font-size: 1.0625rem;
    line-height: 1.2
  }
}

#flat-content-container {
  color: #fff;
  position: relative;
  z-index: 3
}

#flat-content-container, #flat-content-container section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%
}

#flat-content-container section {
  align-items: center;
  background: #222224;
  box-sizing: border-box;
  flex-shrink: 0;
  justify-content: space-between;
  text-align: center
}

#flat-content-container section h2, #flat-content-container section h3 {
  flex: 0 0 initial;
  font-family: SairaRegular;
  text-transform: uppercase
}

#flat-content-container section .flex-container {
  flex-basis: 0;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
  width: 100%
}

#flat-content-container section .flex-container .img-text-container {
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%
}

#flat-content-container section .flex-container .img-text-container img {
  max-height: 100%
}

#flat-content-container section .flex-container .img-text-container h4 {
  bottom: 2.5rem;
  font-family: SairaRegular;
  font-size: 5rem;
  left: 2.5rem;
  position: absolute;
  -webkit-text-decoration: uppercase;
  text-decoration: uppercase
}

#flat-content-container section .flex-container .img-text-container h4 span {
  font-family: SairaRegular
}

#flat-content-container section .double {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  max-width: 1024px;
  width: 100%
}

#flat-content-container section .double img {
  object-fit: contain;
  width: 50%
}

#flat-content-container section .heading-border {
  background: #f4d283;
  flex: 0 0 initial;
  flex-shrink: 0;
  height: 3px;
  margin: 1.5625rem 0;
  width: 200px
}

#flat-content-container section h2 {
  font-size: 1.5625rem;
  margin: 3.75rem 0 0
}

#flat-content-container section h3 {
  font-size: 1.875rem;
  margin: 0 0 1.875rem
}

#flat-content-container section p {
  flex: 0 0 initial;
  max-width: 1000px;
  padding: 1.25rem
}

#flat-content-container section img {
  flex-grow: 1;
  max-width: 100%;
  object-fit: contain
}

#flat-content-container section .tags {
  display: flex;
  font-size: 2.5rem;
  justify-content: space-between;
  margin: 3rem 0 2.5rem;
  max-width: 1000px;
  width: 100%
}

#flat-content-container section .tags span {
  font-family: SairaRegular;
  text-transform: uppercase
}

#inspector-host, #sceneExplorer {
  position: fixed !important
}

#intro-video {
  height: 100%;
  left: 0;
  object-fit: cover;
  opacity: 1;
  position: fixed;
  top: 0;
  transition: opacity 1s ease, visibility 1s ease;
  visibility: visible;
  width: 100%;
  z-index: 8000
}

#intro-video.hidden {
  opacity: 0;
  visibility: hidden
}

#loading-screen, * {
  font-family: SairaRegular
}

#loading-screen {
  align-items: center;
  background: #141f3f;
  background: radial-gradient(circle, #141f3f 0, #0a122b 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  height: 100%;
  justify-content: center;
  left: 0;
  letter-spacing: .1875rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12000
}

#outline {
  stroke-dasharray: 2.2px, 220px;
  stroke-dashoffset: 0;
  -webkit-animation: anim 1.6s linear infinite;
  animation: anim 1.6s linear infinite
}

@-webkit-keyframes anim {
  12.5% {
    stroke-dasharray: 30.8px, 220px;
    stroke-dashoffset: -24.4px
  }

  43.75% {
    stroke-dasharray: 77px, 220px;
    stroke-dashoffset: -77px
  }

  to {
    stroke-dasharray: 2.2px, 220px;
    stroke-dashoffset: -217.8px
  }
}

@keyframes anim {
  12.5% {
    stroke-dasharray: 30.8px, 220px;
    stroke-dashoffset: -24.4px
  }

  43.75% {
    stroke-dasharray: 77px, 220px;
    stroke-dashoffset: -77px
  }

  to {
    stroke-dasharray: 2.2px, 220px;
    stroke-dashoffset: -217.8px
  }
}

#loading-description {
  margin-top: -1.875rem
}

.home-header {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

#section-1 .content {
  margin-top: 3.125rem
}

#section-navigation {
  margin-right: 3.75rem
}

@media screen and (max-width:1600px) {
  #section-1 .content {
    max-width: 30rem;
  }
  #section-5 .content {
    max-width: 50rem;
  }
}

@media screen and (max-width:1400px) {
  #section-1 .content {
    max-width: 25rem;
  }
  #section-5 .content {
    max-width: 30rem;
  }
}

@media screen and (max-width:1200px) {
  #section-1 .content {
    max-width: 20rem;
  }
  #section-5 .content {
    max-width: 25rem;
  }
}

@media screen and (max-width:1000px) {
  #scroll-animation-container section#section-1 .content,
  #scroll-animation-container section#section-2 .content,
  #scroll-animation-container section#section-5 .content {
    left: unset;
    right: unset;
    top: unset;
    transform: translate(0%, 0%) !important;
    bottom: 2.5rem;
  }

  #scroll-animation-container section#section-2 .content {
    right: 6rem;
  }

  #scroll-animation-container section#section-1 p,
  #scroll-animation-container section#section-2 p,
  #scroll-animation-container section#section-5 p {
    font-size: 1.0625rem;
    line-height: 1.2rem;
  }
}

@media screen and (max-width:620px) {
  #section-navigation {
    margin-right: 0
  }

  .description {
    display: none;
  }

  #scroll-animation-container #section-1 {
    top: 4500px;
  }

  #scroll-animation-container section#section-2 .content {
    right: unset;
  }

  #scroll-animation-container section#section-3 .content {
    left: 1.5rem;
    transform: translate(0,0) !important;
    top: 9.5rem;
  }

  #scroll-animation-container section#section-4 .content {
    left: unset;
    right: unset;
    top: unset;
    transform: translate(0%, 0%) !important;
    bottom: 2.5rem;
  }

  #scroll-animation-container section#section-4 p {
    font-size: 1.0625rem;
    line-height: 1.2rem;
  }
}

#section-container {
  overflow-x: hidden !important;
}

@media screen and (max-width: 500px) {
  body {
    width: 100vw;
    overflow-x: hidden;
  }
  .home-header .navigation-area {
    margin-top: 6rem;
  }

  .nav-logo-welcome {
    height: 3.75rem;
    width: 8.125rem;
    margin-left: -0.625rem;
  }
  .description {
    display: none;
  }
  .elipse-1,
  .elipse-2,
  .nav-elipse-2,
  .elipse-2-landing,
  .elipse-2-road {
    display: none;
  }
}
