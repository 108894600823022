:root {
  --dark-background: #1e1e1e;
  --white-text: #fff;
  --gold-header: #c6a15a;
  --dark-text: #222224;
  --terms-heading: #e0e0e0;
  --white-shade-text: #dedad7;
  --brighter-gold: #be8f39;
  --faded-text: #626262;
  --gold-title: #f9c05f;
}

@font-face {
  font-family: HavelockMedium;
  font-style: normal;
  font-weight: 400;
  src: local("HavelockMedium"), url(/assets/fonts/Medium.otf) format("truetype");
}

@font-face {
  font-family: SairaRegular;
  font-style: normal;
  font-weight: 400;
  src: local("SairaRegular"),
    url(/assets/fonts/Saira-Regular.ttf) format("truetype");
}

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100%;
  margin: 0;
  color: var(--white-text);
  background-color: transparent;
}

html {
  background-color: var(--dark-background);
}

:host-context #wcm-modal {
  z-index: 10000;
}

app-root,
app-root > div > app-home {
  height: 100%;
  width: 100vw;
  display: block;
  overflow: hidden;
}

*,
ul,
li {
  font-family: SairaRegular;
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

h1 {
  color: var(--dark-text);
  font-family: HavelockMedium;
  font-size: 3rem;
  letter-spacing: 0.22em;
  text-align: center;
  text-transform: uppercase;
}

p {
  margin-bottom: 0.625rem;
}

hr {
  background-color: var(--brighter-gold);
  height: 1px;
  opacity: 0.25;
  margin: 0.625rem 0;
  border: none;
}

a {
  color: var(--white-shade-text);
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.3s;
}

a.faded-link {
  color: var(--faded-text);
  font-weight: 500;
  font-size: 1.1rem;
}

a.white-link {
  color: var(--white-text);
  font-weight: 500;
  font-size: 1.1rem;
}

a.icon-link {
  font-size: 1.3rem;
  color: var(--white-text);
  opacity: 0.64;
  transition: all 0.3s;
}

a.back-button {
  position: absolute;
  left: 3.125rem;
  top: 2.8rem;
}

a.back-button > app-icon {
  position: relative;
  top: -1px;
  margin-right: 0.3rem;
}

a:hover {
  color: var(--gold-header);
}

button {
  color: var(--dark-text);
  transition: all 0.3s;
  border-radius: 5px;
  outline: none !important;
  box-shadow: none !important;
}

button.btn-action,
a.btn-action {
  background: linear-gradient(
    295.38deg,
    #ffe5a2 4.89%,
    #bf841a 50.44%,
    #ffcd74 95.06%
  );
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.625rem 1.25rem;
  text-transform: uppercase;
  transition: all 0.3s;
}

button.btn-outline {
  background: transparent;
  border: 0.125rem solid var(--gold-header);
  color: var(--white-text);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.24rem 1.6rem;
  text-transform: uppercase;
}

button:hover {
  color: #212529;
}

button.btn-action:hover,
a.btn-action:hover {
  background: linear-gradient(
    295.38deg,
    #bf841a 4.89%,
    #ffcd74 50.44%,
    #bf841a 95.06%
  );
}

input,
textarea {
  background-color: initial;
  border: 2px solid var(--gold-header);
  border-radius: 5px !important;
  color: var(--white-shade-text);
  padding: 0.625rem;
  width: 100%;
}

input:focus-visible,
textarea:focus-visible {
  outline: 1px solid var(--gold-header);
}

.input-group > label > span {
  color: red;
}

.row {
  margin-right: calc(var(--bs-gutter-x) * -0.3125);
  margin-left: calc(var(--bs-gutter-x) * -0.3125);
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * 0.3125);
  padding-left: calc(var(--bs-gutter-x) * 0.3125);
}

section.content-section {
  padding: 0 18.75rem;
  margin-top: 4.7rem;
}

section.content-section > div > div > h1 {
  color: var(--white-text);
  text-align: left;
  font-family: HavelockMedium;
  font-size: 2.8rem;
  letter-spacing: 0.22em;
  text-transform: uppercase;
}

section.content-section > div > div > h2 {
  color: var(--gold-header);
  font-size: 1.25rem;
  text-transform: uppercase;
}

section.content-section > div > div > p {
  font-size: 1rem;
  line-height: 2rem;
  opacity: 0.64;
  padding-left: 0;
}

div.background-highlight {
  position: absolute;
  background: hsla(41, 68%, 68%, 0.19);
  border: 1px solid #000;
  filter: blur(120px);
  height: 502px;
  width: 502px;
}

main > div.background-highlight:nth-child(1) {
  left: -270px;
  top: 732px;
}

main > div.background-highlight:nth-child(2) {
  left: 85%;
  top: 1048px;
}

.scroll::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--dark-background);
}

.scroll::-webkit-scrollbar-thumb {
  background-color: var(--gold-header);
  border-radius: 10px;
  border: 5px solid var(--dark-background);
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: var(--brighter-gold);
}

.scroll::-webkit-scrollbar-corner {
  background-color: var(--dark-background);
}

.gold-bordered-block {
  width: 100%;
  border: 2px solid var(--gold-header);
  border-radius: 5px !important;
  color: var(--white-shade-text);
  background-color: transparent;
  padding: 0.625rem;
}

.qr-code {
  padding: 0.625rem;
  background-color: #ffffff;
  border-radius: 0.3125rem;
  margin-bottom: 1.25rem;
}

app-slide > div > div > * {
  display: block;
  height: 100%;
  overflow: hidden;
}

.marketplace-layout h1 {
  margin-bottom: 1.25rem;
}

.marketplace-layout .d-grid {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.marketplace-layout .gold-bordered-block {
  border: 3px solid var(--gold-header);
  border-radius: 28px !important;
  background-color: var(--dark-text);
}

.marketplace-layout .slide-options {
  padding: 1rem 1rem 1.45rem 1rem;
}

.marketplace-layout .slide-options > div {
  flex: 1 1;
}

.marketplace-layout .slide-options > button:last-child,
.asset-content > button:last-child {
  margin-top: 0.5rem;
}

.marketplace-layout .asset-content {
  padding: 0 0.5rem 1rem 0.5rem;
}

.navigation-menu-3d > app-navbar > section {
  overflow: hidden !important;
  transition: none;
}

.navigation-menu-3d > app-navbar > section > div > app-icon {
  display: none;
}

.navigation-menu-3d > app-navbar > section > nav > ul > li:nth-child(1) {
  display: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
  min-height: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

@media screen and (max-width: 1950px) {
  .marketplace-layout .d-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1650px) {
  .marketplace-layout .d-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1500px) {
  section.content-section {
    padding: 0 12.5rem;
  }
}

@media screen and (max-width: 1300px) {
  section.content-section {
    padding: 0 11rem;
  }
}

@media screen and (max-width: 1200px) {
  section.content-section {
    padding: 0 6.25rem;
  }

  .marketplace-layout .d-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 966px) {
  section.content-section {
    padding: 0 3.125rem;
  }

  section.content-section > div > div > h1 {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 768px) {
  section.content-section {
    padding: 0 2rem;
  }

  h1 {
    font-size: 2.25rem;
    letter-spacing: 0.11em;
  }

  p {
    font-size: 0.85rem;
  }

  section.content-section > div > div:nth-child(2) {
    margin-top: 3.125rem;
  }

  .marketplace-layout .d-grid {
    grid-template-columns: 1fr;
  }
}
